<template>
  <div id='MCN'>
<!--    <img src='../assets/mcn/mcn-banner.png' alt='' class='banner-img'>-->
    <div class='banner_1'>
      <div class='swiper_content'>
        <!--        <div class="swiper-container">-->
<!--        <swiper :options='swiperOptions1'>-->
<!--          <swiper-slide v-for='(item,index) in logoList' :key='index'>-->
        <div v-if='logoList.length > 0'>
          <a :href='logoList[0].link_url&&logoList[0].link_url.indexOf("http") >= 0  ? logoList[0].link_url : null' target='_blank'><img :src='logoList[0].img'/></a>
        </div>
<!--          </swiper-slide>-->
          <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
          <!--                      <div class="swiper-pagination" slot="pagination"></div>-->
          <!-- 如果需要导航按钮 -->
          <!--          <div class='swiper-button-prev prev1' slot='button-prev'></div>-->
          <!--          <div class='swiper-button-next next1' slot='button-next'></div>-->
<!--        </swiper>-->
        <!--      </div>-->
      </div>
    </div>
    <!--    头部达人-->
    <div class='head-info-item'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{$t('LIVE.headMaster')}}</span>
        </div>
        <div class='anchor-content-info headMaster'>
          <div class="swiper swiper1">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-slide1" v-for='(item,index) in header_anchor' :key='index'>
                <div class="anchor-slide" style='width: 100%; display: flex;flex-flow: row nowrap;justify-content: space-around'>
                  <div class='anchor-slide-item' v-for='(it,idex) in item' :key='idex'>
                    <a :href='it.link_url && it.link_url.indexOf("https") >= 0  ? it.link_url : null' target='_blank' style='width: 343px'>
                      <img :src='it.img' alt='' class="anchor-avatar" style='width: 343px'>
                    </a>
                  </div>
                </div>
                <!--               <div class="anchor-list">-->
                <!--                 <div>{{ item.title }}</div>-->
                <!--                 <div>初夏蓝天杯-季军 十大影响力主播</div>-->
                <!--                 <div>抖音号:tiancidaqiao</div>-->
                <!--                 <span @click='join_hx'>加入华星 <i class='el-icon-d-arrow-right'></i></span>-->
                <!--               </div>-->
<!--                <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank' style='width: 100%'>-->
<!--                  <img :src='item.img' alt='' class="anchor-avatar">-->
<!--                </a>-->
                <!--                 <div class='know_us'>-->
                <!--                   <a :href='item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank' style='width: 100%'><el-button class='know_more'>点击查看>>></el-button></a>-->
                <!--                 </div>-->
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>

            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev swiper-button-top swiper-top2"></div>
            <div class="swiper-button-next swiper-button-bottom swiper-bottom2"></div>
          </div>
        </div>
      </div>
    </div>
    <!--    热门达人-->
    <div class='head-info-item'>
      <div class='header-anchor' style='width: 62%'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{$t('LIVE.hotMaster')}}</span>
        </div>
        <div class='hot-talent'>
          <div class="swiper swiper4">
            <div class="swiper-wrapper" style='flex-direction: initial;'>
              <div class="swiper-slide" v-for='(item, index) in popular_talent' :key='index'>
                <div class='talent-list' >
                  <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank'><img :src='item.img' alt='' class="avatar-talent"></a>
                </div>
              </div>
            </div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
    </div>
    <div class='head-info-item'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{$t('LIVE.HighCases')}}</span>
        </div>
        <div class='quality_cases'>
          <div class="swiper swiper5">
            <div class="swiper-wrapper">
              <div class="swiper-slide cases-list" v-for='(item, index) in cases_list' :key='index'>
                <div class='lists-con'>
                  <a target='_blank' style='height: 446px;width: 100%' :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null'><img :src='item.img' alt='' class="yanxuan-avatar"></a>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination yanxuan_pagination"></div>
            <div class="swiper-button-prev  swiper-button-top swiper_top"></div>
            <div class="swiper-button-next  swiper-button-bottom swiper_bottom"></div>
          </div>
        </div>
      </div>
    </div>
    <!--    爆款视频-->
    <div class='head-info-item' style='background: #F6F6F6;'>
      <div class='header-anchor' style='width: 62%;position: relative'>
<!--          position: relative;
  .video-player{
    position: absolute;
    width: 376px;
    height: 700px;
    z-index: 2999!important;
    top: -120px;
    left: calc(50% - 188px);
  }-->
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{$t('LIVE.PopularVideo')}}</span>
        </div>
        <div class='popular-video'>
          <div class="swiper swiper2">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for='(item, index) in popular_video' :key='index' style='position: relative'  @click='showVideoSrc(item)'>
<!--                <a target='_blank' style='height: 471px;width: 232px;' :href='item.link_url.indexOf("http") >= 0  ? item.link_url : null'>-->
                  <img :src='item.img' alt='' class="avatar-video">
<!--                </a>-->
                <div class='video-button'>
                  <i class='el-icon-video-play'></i>
                </div>
              </div>
            </div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
          <el-dialog
            :visible.sync="dialogVisible"
            width='376px'
            height="700px"
            :show-close="false"
            custom-class='video-dialog-s'
            :modal='false'
            @close='closeChange'
            top="15vh">
            <video-player class="video-player vjs-custom-skin" v-if='videoSrc'
                          ref="videoPlayer"
                          :playsinline="false"
                          :options="playerOptions">
            </video-player>
          </el-dialog>
        </div>
      </div>
    </div>
    <!--    广告爆款达人-->
    <div class='head-info-item'>
      <div class='header-anchor mobile-terminal-header'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{$t('LIVE.AdvertisingHit')}}</span>
        </div>
        <div class='pop-advertising cooperative-brands'>
          <div class="swiper swiper3">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for='(item, index) in pop_advertising' :key='index'>
                <div class='talent-info-s'>
                  <div class='talent-contents'>
                    <div style='text-align: center'>
                      <img :src='item.head_img' alt='' class="info-avatar">
                      <div class='nickname-info'>{{ item.nikename }}</div>
                    </div>
                    <div>
                      <div>{{$t('LIVE.liveId')}}: {{ item.live_id }}</div>
                      <div>{{$t('LIVE.TiktokFans')}}: {{ item.fans_num }}</div>
                      <div>{{$t('LIVE.TiktokLikes')}}: {{ item.like_num }}</div>
                    </div>
                  </div>
                </div>
                <div class="list-swiper-brands">
                  <div class='name-title'><span>{{$t('LIVE.cooperativeBrand')}}</span></div>
                  <div style='display: flex'>
                    <div class='brand-list-s'>
                      <a target='_blank' :href='item.url_one && item.url_one.indexOf("http") >= 0  ? item.url_one : null' style='width: 218px;height: 100%'>
                        <img :src='item.img_one' alt='' class="avatar-advertising">
                        <div class='top_'>
                          <div class='top-title-n'>
                            <div>{{ item.game_one }}</div>
                            <div class='cpm-data'>CPM:{{ item.cpm_one }}</div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class='brand-list-s'>
                      <a target='_blank' :href='item.url_two&&item.url_two.indexOf("http") >= 0  ? item.url_two : null' style='width: 218px;height: 100%'>
                        <img :src='item.img_two' alt='' class="avatar-advertising">
                        <div class='top_'>
                          <div class='top-title-n'>
                            <div>{{ item.game_two }}</div>
                            <div class='cpm-data'>CPM:{{ item.cpm_two }}</div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class='brand-list-s'>
                      <a target='_blank' :href='item.url_three&&item.url_three.indexOf("http") >= 0  ? item.url_three : null' style='width: 218px;height: 100%'>
                        <img :src='item.img_three' alt='' class="avatar-advertising">
                        <div class='top_'>
                          <div class='top-title-n'>
                            <div>{{ item.game_three }}</div>
                            <div class='cpm-data'>CPM:{{ item.cpm_three }}</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev button-brands-left"></div>
            <div class="swiper-button-next button-brands-right"></div>
          </div>
        </div>
      </div>
    </div>
    <div class='recruit-item'>
      <swiper :options='swiperOptions2'>
        <swiper-slide v-for='(item,index) in mcn_join_img' :key='index'>
          <img :src='item' alt=''>
        </swiper-slide>
      </swiper>
      <div class="content-s">
        <div class='form-item-s'>
          <div class="name-d">{{$t('LIVE.TalentRecruitment')}}</div>
          <el-form ref="form" :model="form" :rules='rules'  label-position="left">
            <div class="back-s">
              <el-form-item label="" prop='live_plat'>
                <el-input v-model="form.live_plat" size='mini' :placeholder="$t('LIVE.broadcastingPlatform')"></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='live_id'>
                <el-input v-model="form.live_id" size='mini' :placeholder="$t('LIVE.LiveAccount')"></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='field'>
                <el-input v-model="form.field" size='mini' :placeholder="$t('LIVE.expertise')"></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='wechat'>
                <el-input v-model="form.wechat" size='mini' :placeholder="$t('LIVE.wechat')"></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='city'>
                <el-input v-model="form.city" size='mini' :placeholder="$t('LIVE.city')"></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='sketch'>
                <el-input v-model="form.sketch" size='mini' :placeholder="$t('LIVE.phone')"></el-input>
              </el-form-item>
            </div>
            <el-form-item label-width='0' style='text-align: center'>
              <el-button type="primary" size='small' @click="onSubmit('form')">{{$t("LIVE.submitText")}}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class='data-see'>
          <img src='../assets/mcn/mcn-mirror-image.png' alt='' class="image-platform">
        </div>
        <div class='form-item-s'>
          <div class="name-d">{{$t("LIVE.businessCooperation")}}</div>
          <el-form ref="form_1" :model="form_1" :rules='rules_1' label-position="left">
            <div class="back-s">
              <el-form-item label="" prop='brand'>
                <el-input v-model="form_1.brand" size='mini' :placeholder='$t("LIVE.brandText")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='live_id'>
                <el-input v-model="form_1.live_id" size='mini' :placeholder='$t("LIVE.intention")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='desc'>
                <el-input v-model="form_1.desc" size='mini' :placeholder='$t("LIVE.cooperationNeeds")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='is_brand'>
                <el-input v-model="form_1.is_brand" size='mini' :placeholder='$t("LIVE.brandDirectSale")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='wechat'>
                <el-input v-model="form_1.wechat" size='mini' :placeholder='$t("LIVE.wechat")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='city'>
                <el-input v-model="form_1.city" size='mini' :placeholder='$t("LIVE.phone")'></el-input>
              </el-form-item>
            </div>
            <el-form-item label-width='0' style='text-align: center'>
              <el-button type="primary" size='small' @click="onSubmit_1('form_1')">{{$t("LIVE.submitText")}}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import homeBanner from '../components/homeBanner'
import Swiper from 'swiper'
import { getMcnBanner, liveJoin, shopJoin, getMcnHotLive } from '@/api/mcn'
import { Logo, index_List } from '@/api/banner'

export default {
  name: 'MCN',
  components: {
    // homeBanner
  },
  data() {
    return{
      logoList:[],
      Index:0,
      swiperOptions1: {
        // 自动播放
        autoplay: {
          delay: 3000,
        },
        // 环路播放
        loop: true,
        // 切换效果
        effect: 'slide',
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.next1',
          prevEl: '.swiper-button-prev.prev1',
        }
      },
      swiperOptions2: {
        // 自动播放
        autoplay: {
          delay: 3000,
        },
        // 环路播放
        loop: true,
        // 切换效果
        effect: 'slide',
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.next1',
          prevEl: '.swiper-button-prev.prev1',
        }
      },
      swiper: {
        direction: 'vertical', // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerColumn: 3, //竖着展示3张图片
        slidesPerGroup: 1,
        slidesPerColumnFill:'row',
        // slidesPerGroupSkip: 4,
        // slidesPerGroup: 3,
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          bulletClass : 'my-bullet',//需设置.my-bullet样式
          bulletActiveClass: 'my-bullet-active',
          clickable :true,
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // 如果需要滚动条
        scrollbar: {
          el: '.swiper-scrollbar',
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide :0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      swiper2: {
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          bulletClass : 'my-bullet-2',//需设置.my-bullet样式
          bulletActiveClass: 'my-bullet-active',
          clickable :true,
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 5,
        slidesPerGroup: 1,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide :0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      swiper3: {
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          bulletClass : 'my-bullet-2',//需设置.my-bullet样式
          bulletActiveClass: 'my-bullet-active',
          clickable :true,
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        roundLengths : true,
        autoplay: true,
        initialSlide :0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      swiper4: {
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          // bulletClass : 'my-bullet-2',//需设置.my-bullet样式
          // bulletActiveClass: 'my-bullet-active',
          clickable :true,
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 4,
        slidesPerGroup: 4,
        roundLengths : true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide :0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      swiper5: {
        direction: 'vertical', // 垂直切换选项
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          bulletClass : 'my-bullet',//需设置.my-bullet样式
          bulletActiveClass: 'my-bullet-active',
          clickable :true,
        },
        navigation: {
          nextEl: '.swiper-button-next.swiper_bottom',
          prevEl: '.swiper-button-prev.swiper_top',
        },
        height: 460,//你的slide高度 强制性 垂直方向生效
        effect: 'coverflow', //进出动画
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        //longSwipesRatio: 0.2, //滑动多少就可以滑动
        coverflowEffect: {
          slideShadows: false, // 页面阴影效果
          rotate: 0,// 旋转的角度
          stretch: 510,// 拉伸 图片间左右的间距和密集度
          depth: 0,// 深度 切换图片间上下的间距和密集度
          modifier: .8,// 修正值 该值越大前面的效果越明显
        },
        slidesPerColumn: 1, //竖着展示2张图片
        slidesPerGroup: 1,
        on: {
          progress: function (progress) {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = this.slides[i].progress;
              slide.css('opacity', 1);
              if (slideProgress == -1) {
                slide.css('opacity', 0.5);
              }
              if (slideProgress == -2) {
                slide.css('opacity', 0.3);
              }
              if (slideProgress <= -3 || slideProgress >= 1) {
                slide.css('opacity', 0);
              }
            }
          },
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide :0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      form: {
        live_plat: '', // 直播平台
        live_id: '', // 直播账号
        field: '', // 擅长领域
        wechat: '', // 微信号
        city: '', // 所在城市
        sketch: '' // 曾经直播成绩简述
      },

      form_1: {
        brand: '', // 品牌/商品名称
        live_id: '', // 合作意向主播
        desc: '', // 合作需求简介
        is_brand: '', // 是否为品牌直营
        wechat: '', // 微信号
        city: '', // 所在城市
      },

      img_banner: '',
      header_anchor: [], // 头部达人
      popular_talent: [], // 热门达人
      popular_video: [], // 爆款视频
      pop_advertising: [], // 广告爆款达人
      mcn_join_img: '', // 达人招募背景图
      videoSrc:"",
      playerOptions:{},
      cases_list: [], // 优质广告案例
      dialogVisible: false
    }
  },
  computed:{
    rules:function(){
      let data={
          live_plat: [
            { required: true, message: this.$t('LIVE.rules_platform'), trigger: 'blur' }
          ],
          live_id: [
            { required: true, message: this.$t('LIVE.rules_accout'), trigger: 'blur' }
          ],
          field: [
            { required: true, message: this.$t('LIVE.rules_good'), trigger: 'blur' }
          ],
          wechat: [
            { required: true, message:  this.$t('LIVE.rules_wechat'), trigger: 'blur' }
          ],
          city: [
            { required: true, message: this.$t('LIVE.rules_city'), trigger: 'blur' }
          ],
          sketch: [
            { required: true, message: this.$t('LIVE.rules_phone'), trigger: 'blur' }
          ],
        }
        return data;
    },
    rules_1:function(){
      let data= {
          brand: [
            { required: true, message: this.$t('LIVE.rules_brandText'), trigger: 'blur' }
          ],
          live_id: [
            { required: true, message: this.$t('LIVE.rules_intention'), trigger: 'blur' }
          ],
          desc: [
            { required: true, message: this.$t('LIVE.rules_cooperationNeeds'), trigger: 'blur' }
          ],
          is_brand: [
            { required: true, message: this.$t('LIVE.rules_brandDirectSale'), trigger: 'blur' }
          ],
          wechat: [
            { required: true, message: this.$t('LIVE.rules_wechat'), trigger: 'blur' }
          ],
          city: [
            { required: true, message: this.$t('LIVE.rules_phone'), trigger: 'blur' }
          ]
        }
        return data;
    }
  },
  mounted() {
    this.get_Logo()
    this.get_index_List() // 达人招募背景图
    this.get_mcnBanner(1) // 头部达人
    this.get_mcnBanner(2) // 热门达人
    this.get_mcnBanner(3) // 爆款视频
    this.get_mcnBanner(5) // 优质广告案例
    this.get_mcnHotLive() // 广告爆款达人
    let  language=localStorage.getItem('locale')
  },
  methods: {
    closeChange: function() {
      this.$refs.videoPlayer.player.pause() // 暂停
      // console.log(43444, this.$refs.videoPlayer.player.paused())
    },
    showVideoSrc:function(params){
      console.log('4545454',params)
      this.dialogVisible = true
      let options={
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        playbackRates: false, //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "4:3", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src:params.video_url
            // src: require("../assets/Index/video1.mp4"), //视频url地址
          },
        ],

        // poster: require("../assets/video_poster.png"), //你的封面地址
        poster: params.img, //你的封面地址
// width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 时间分割线
          durationDisplay: true, // 总时间
          remainingTimeDisplay: true, // 剩余播放时间
          progressControl: true, // 进度条
          fullscreenToggle: false  // 全屏按钮
        }
      }
      this.$set(this,'videoSrc',params.video_url)
      this.$set(this,'playerOptions',options)
      // console.log('------------------params',params.link_url)
    },
    get_Logo: function() {
      Logo(3).then(resp => {
        if (resp.status == 200) {
          this.$set(this, 'logoList', resp.data)
        }
      }).catch(error => {

      })
    },
    // 达人招募背景图
    get_index_List: function() {
      index_List().then(resp => {
        // console.log('12121212',resp)
        if (resp.status == 200) {
          this.$set(this, 'mcn_join_img', resp.data.mcn_join_img)
        }
      }).catch(error => {
      })
    },
    // 广告爆款达人
    get_mcnHotLive: function() {
      getMcnHotLive().then(resp => {
        // console.log('89898989',resp.data)
        if(resp.status == 200) {
          this.$set(this, 'pop_advertising', resp.data)
          this.$nextTick(()=>{
            var mySwiper3 = new Swiper ('.swiper3', this.swiper3)
          })

        } else {
          this.$message(resp.data.msg)
        }
      })
    },
    get_mcnBanner: function(data) {
      getMcnBanner({ type: data }).then(resp => {
        if(resp.status == 200) {
          if(data == 1) {
            let arrList=[]
            var len=Math.ceil(resp.data.length/3)
            for(var i=0;i<len;i++){
              arrList.push(resp.data.splice(0,3))
            }
            this.$set(this, 'header_anchor', arrList)
            console.log('-----------7878888',arrList)
            let index0=resp.data.length >0 ? Number(resp.data.length/3) : 0
            console.log('index0',index0)
            this.$nextTick(()=> {
              let mySwiper = new Swiper('.swiper1', {
                direction: 'vertical', // 垂直切换选项
                loop: true, // 循环模式选项
                // 如果需要分页器
                pagination: {
                  el: '.swiper-pagination',
                  bulletClass : 'my-bullet',//需设置.my-bullet样式
                  bulletActiveClass: 'my-bullet-active',
                  clickable :true,
                  // dynamicBullets: true,
                  // dynamicMainBullets: 3,
                },

                // 如果需要前进后退按钮
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                },
                // 如果需要滚动条
                scrollbar: {
                  el: '.swiper-scrollbar',
                },
                autoplay: {
                  delay: 5000,
                  stopOnLastSlide: false,
                  disableOnInteraction: false,
                },
                initialSlide :0,
                observer:true,//修改swiper自己或子元素时，自动初始化swiper
                observeParents:true//修改swiper的父元素时，自动初始化swiper
              },)
              function footSlideTo(pageNum) {
                console.log('pageNumpageNumpageNumpageNum',pageNum,Number(index0 - 1))
                // 跳转到 指定页，过渡动画时间，回调函数
                // this.$nextTick(()=>{
                  mySwiper.slideTo(pageNum, 300, false);
                // })

              }
            })

          }
          if(data == 2) {
            this.$set(this, 'popular_talent', resp.data)
            this.$nextTick(()=> {
              var mySwiper4 = new Swiper('.swiper4', this.swiper4)
            })
          }
          if(data == 3) {
            this.$set(this, 'popular_video', resp.data)
            this.$nextTick(()=> {
              let mySwiper2 = new Swiper('.swiper2', this.swiper2)
            })
          }
          if(data == 5) {
            this.$set(this, 'cases_list', resp.data)
            this.$nextTick(()=> {
              let mySwiper5 = new Swiper('.swiper5', this.swiper5)
            })
          }
        } else {
          this.$message(resp.data.msg)
        }
      }).catch(err => {
      })
    },
    prevChange:function(){
      console.log('9999',this.Index)
    },
    // 达人招募 -- 提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            platform: this.form.live_plat, //	直播平台
            live_id: this.form.live_id, //	直播账号
            territory: this.form.field, //	擅长领域
            wechat: this.form.wechat, //	微信号
            city: this.form.city, //	所在城市
            desc: this.form.sketch, //	曾经直播成绩简述
          }
          liveJoin(obj).then(resp => {
            if(resp.status == 200) {
              this.$message({
                message: '提交成功',
                type: 'success'
              });
              this.form = {
                live_plat: '', // 直播平台
                live_id: '', // 直播账号
                field: '', // 擅长领域
                wechat: '', // 微信号
                city: '', // 所在城市
                sketch: '' // 曾经直播成绩简述
              }
            } else {
              this.$message(resp.msg)
            }
          }).catch(err => {
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 商务合作 -- 提交
    onSubmit_1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          shopJoin(this.form_1).then(resp => {
            if(resp.status == 200) {
              this.$message({
                message: '提交成功',
                type: 'success'
              });
              this.form_1 = {
                brand: '', // 品牌/商品名称
                live_id: '', // 合作意向主播
                desc: '', // 合作需求简介
                is_brand: '', // 是否为品牌直营
                wechat: '', // 微信号
                city: '', // 所在城市
              }
            } else {
              this.$message(resp.data.msg)
            }
          }).catch(err => {
            this.$message.error('请稍后重试');
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped lang='scss'>
.head-info-item{
  //display: flex;
  position: relative;
  padding: 30px 0;
  .header-anchor{
    width: 64%;
    margin: auto;
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333;
    position: relative;
    line-height: 30px;
    .video-player{
      position: absolute;
      width: 376px;
      height: 650px;
      z-index: 2999!important;
      top: -40px;
      left: calc(50% - 188px);
      transition: all 2s;
    }
    .images{
      width: 281px;
      position: absolute;
      left: 50%;
    }
    .advertising-img{
      width: 340px;
    }
    .anchor-content-info{
      height: 580px;
      width: 1200px;
      overflow: hidden;
      margin: auto;
      .avatar2{
        width: 343px;
        height: 100%;
        //background: rgba(213, 71, 36, 0.1);
        border-radius: 10px;
        cursor: pointer;
      }
      .nickname-s{
        position: absolute;
        bottom: 0;
        color: #fff;
        line-height: 45px;
        font-size: 14px;
        font-family: SourceHanSansSC;
        font-weight: 500;
        width: 92%;
        display: flex;
        justify-content: space-between;
        background-color: rgba(213, 71, 36, 0.2);
        padding: 0 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .swiper-slide {
        width: 30% !important;
        height: 580px;
      }
      .swiper-slide1{
        width: 100% !important;
      }
    }
    .address-word{
      width: 181px;
    }
  }
  .rectangle-position{
    position: absolute;
    bottom: 0;
    left: 60px;
    img{
      width: 104px;
      height: 73px;
    }
  }
  .rectangle-right{
    position: absolute;
    top: 80px;
    right: 100px;
    img{
      width: 106px;
      height: 73px;
    }
  }
  .rights{
    right: 160px;
  }
  .swiper-slide{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #666666;
  }
  .anchor-list{
    padding: 30px 0 0 70px;
  }
  .anchor-list div:nth-child(1){
    font-size: 20px;
    color: #333333;
    line-height: 50px;
  }
  .anchor-list div:nth-child(3){
    margin: 50px 0 150px 0;
  }
  .anchor-list span:nth-child(4){
    background: #D54724;
    border-radius: 23px;
    padding: 8px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
  }
  .anchor-avatar{
    width: 428px;
    height: 100%;
    border-radius: 40px;
  }
}
.banner_1 {
  width: 100%;
  height: 700px;

  .swiper_content {
    width: 100%;
    height: 700px;

    .swiper-container {
      width: 100%;
      height: 700px;

      .swiper-wrapper {
        width: 100%;
        height: 700px;

        .swiper-slide {
          width: 100%;
          height: 700px;

          img {
            width: 100%;
            height: 700px;
          }
        }
      }
    }
    img {
      width: 100%;
      height: 700px;
    }
  }
}
.list-swiper-s{
  position: relative;
  //border: 1px solid #D54724;
  //box-shadow: 2px 3px 10px 0px rgba(34, 20, 22, 0.16);
  border-radius: 10px;
  a{
    width: 342px;
    height: 100%;
  }
}
.list-swiper-s:hover{
  background: #D54724;
  box-shadow: 2px 3px 10px 0px rgba(34,20,22,0.16);
}
.left-ellipse{
  width: 505px;
  height: 724px;
  position: absolute;
  bottom: -120px;
  left: 0;
}
.right-semicircle{
  width: 338px;
  height: 624px;
  position: absolute;
  top: -50px;
  right: 0;
}
.hot-talent{
  display: flex;
  width: 1200px;
  height: 638px;
  overflow: hidden;
  .talent-list{
    text-align: center;
    font-size: 20px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
    .avatar-talent{
      width: 299px;
      height: 638px;
      //background: #F5F6F8;
      //border: 1px solid #E84B24;
      //box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
    }
    .line{
      width: 25px;
      height: 2px;
      background: #E84B24;
      margin: 10px auto;
      margin-top: 40px;
    }
    .line-s{
      width: 25px;
      height: 2px;
      background: #E84B24;
      margin: 25px auto;
    }
    .nickname-item{
      text-align: center;
    }
  }
}
.popular-video{
  height: 501px;
  width: 100%;
  overflow: hidden;
  margin: auto;

  .avatar-video{
    height: 471px;
    width: 232px;
    //border: 1px solid #E84B24;
    border-radius: 30px;
    cursor: pointer;
  }
  .swiper-button-prev{
    left: -60px;
  }
  .swiper-button-next{
    right: -60px;
  }
  .video-button{
    position: absolute;
    width: 46px;
    height: 46px;
    top:calc(50% - 23px);
    left:calc(50% - 23px);
    cursor: pointer;
    i{
      color: #fff;
      font-size: 46px;
      font-weight: 200
    }
  }
}
.pop-advertising{
  height: 438px;
  display: flex;
  margin-bottom: 40px;
  overflow: hidden;
  .talent-info-s{
    font-size: 14px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #666666;
    //background: #F6E8E4;
    border-radius: 40px 0px 0px 40px;
    padding: 40px 0 40px 40px;
    width: 35%;
  }
  .talent-contents{
    //width: 50%;
    margin:auto;
  }
  .talent-contents > div:nth-child(2){
    padding-left: 70px;
  }
  .nickname-info {
    font-size: 18px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
  }
  .info-avatar{
    width: 200px;
    height: 200px;
    border-radius: 50% 50%;
  }
  .cooperative-brands{
    width: 100%;
    background: #D54724;
    border-radius: 40px 40px 40px 40px;
    display: flex;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    padding: 10px;
    .brands-s{
      overflow: hidden;
      margin: 0 15px;
    }
  }
  .avatar-advertising{
    width: 218px;
    height: 100%;
    border-radius: 30px;
  }
}
.quality_cases{
  height: 576px;
  width: 94%;
  overflow: hidden;
  margin: auto;
  //background: #F2F2F2;
  border-radius: 40px 40px 40px 40px;
  //box-shadow: 0px 0px 7px 0px rgba(60,18,7,0.1500);
  .yanxuan_pagination{
    right: -10px;
  }
  .swiper-slide{
    height: auto;
    width: 100%;
  }
}
.lists-con{
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-family: SourceHanSansSC;
  font-weight: 400;
  color: #FFFFFF;
}
.yanxuan-avatar{
  width: 96.5%;
  height: 446px;
}
.cases-list{
  height: 446px;
  width: 100%;
}
.list-swiper-brands{
  position: relative;
  border: 2px solid #fff;
  border-radius: 30px;
  width: 80%;
  display: flex;
  .nickname-brands{
    position: absolute;
    background: #D54724;
    border-radius: 33px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
  .name-title{
    //height: 90px;
    //width: 20px;
    line-height: 20px;
    text-align: center;
    padding: 6px 6px;
    margin-right: 30px;
    span{
      color: #D54724;
      background-color: #F6E8E4;
      padding: 6px 6px;
      display: inline-block;
    }
  }
  .brand-list-s{
    position: relative;
  }
  .top_{
    position: absolute;
    top:3px;
    left: 10px;
    width: 75%;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #262626;
    display: none;
    animation: dong 1s;
    .top-title-n{
      display: flex;
      justify-content: space-between;
    }
    .cpm-data{
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #D54724;
    }
  }
  .brand-list-s:hover .top_{
    display: block;
  }
  @keyframes dong {
    0% {
      transform: translate(0px, -20px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
}
.recruit-item{
  height: 700px;
  width: 100%;
  //background: url("../assets/base-image.png") no-repeat;
  //background-size:100% 700px;
  position: relative;
  img{
    height: 700px;
    width: 100%;
  }
  .content-s{
    width: 55%;
    margin: auto;
    //padding-top: 120px;
    position: absolute;
    top:120px;
    z-index: 2;
    left: 22%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .form-item-s{
    width: 283px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(170, 169, 169, 0.44);
    border-radius: 40px;
    height: 450px;
    padding: 15px;
    .back-s{
      //background: #F19F8A;
      //border-radius: 25px;
      //padding: 0 10px 0 10px;
    }
  }
  .form-item-s .name-d {
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .data-see{
    padding: 10px;
    .image-platform{
      width: 439px;
      height: 268px;
    }
  }
}
.swiper1 {
  width: 100%;
  height: 580px;
  /*margin-left: -100px;*/
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
}
.swiper2{
  width: 100%;
  height: 471px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
}
.swiper3{
  width: 100%;
  height: 438px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
}
.swiper4{
  width: 100%;
  height: 638px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
}
.swiper5 {
  width: 97%;
  height: 576px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
}
.map-container {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  background-color: rgba(213, 71, 36, 0.1);
}
.button-brands-left{
  left:-2%;
}
.button-brands-right{
  right:-5%;
}
  .swiper_bottom,.swiper_top{
    left: 98% !important;
  }
  .headMaster{
    .swiper-slide{
      width: auto!important;
    }
  }

</style>
